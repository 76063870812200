import request from "@/utils/request"

//分页查询
export function getActiveList(data) {
    return request({
        url: '/activeManage/listPage',
        method: 'get',
        params: data
    })
}

//新增活动
export function addActivity(data) {
    return request({
        url: '/activeManage/save',
        method: 'post',
        data
    })
}

//编辑活动
export function editActivity(data) {
    return request({
        url: '/activeManage/update',
        method: 'put',
        data
    })
}

//查看单个活动
export function findById(data) {
    return request({
        url: '/activeManage/findById',
        method: 'get',
        params: data
    })
}

//删除活动
export function deleteActivity(data) {
    return request({
        url: '/activeManage/deleteById',
        method: 'delete',
        params: data
    })
}

//发布活动
export function stateActivity(data) {
    return request({
        url: '/activeManage/state',
        method: 'post',
        data
    })
}

//查看单个活动回顾
export function activeReview(data) {
    return request({
        url: '/activeReview/listAll',
        method: 'get',
        params: data
    })
}

//新增活动回顾
export function AddActiveReview(data) {
    return request({
        url: '/activeReview/save',
        method: 'post',
        data
    })
}

//编辑活动回顾
export function editActiveReview(data) {
    return request({
        url: '/activeReview/update',
        method: 'put',
        data
    })
}

//分页查询作品列表
export function worksMangeList(data) {
    return request({
        url: '/enrollment/listPage',
        method: 'get',
        params: data
    })
}

//查看单个信息收集
export function getInformation(data) {
    return request({
        url: '/information/listById',
        method: 'get',
        params: data
    })
}

//新增信息收集
export function addInformation(data) {
    return request({
        url: '/information/save',
        method: 'post',
        data
    })
}

//修改信息收集
export function editInformation(data) {
    return request({
        url: '/information/update',
        method: 'put',
        data
    })
}

//查看 校方数据详情
export function schoolEnrolment(data) {
    return request({
        url: '/schoolEnrolment/listAll',
        method: 'get',
        params: data
    })
}

//校方二维码
export function qrCodeDownloadShool(data) {
    return request({
        url: '/wxQrc/qrcCode',
        method: 'get',
        params: data
    })
}

//作品单个查看
export function commetById(data) {
    return request({
        url: '/enrollment/listById',
        method: 'get',
        params: data
    })
}

//编辑点评
export function editComment(data) {
    return request({
        url: '/evaluationWork/update',
        method: 'put',
        data
    })
}

//新增点评
export function addComment(data) {
    return request({
        url: '/evaluationWork/save',
        method: 'post',
        data
    })
}