<template>
  <div class="information_table">
    <el-card>
      <div class="position_card">
        <div class="information_table_title">{{ activeName }}</div>
        <div class="information_table_div">
          <div class="title">报名表单</div>
          <el-form
            label-width="80px"
            label-position="right"
            class="information_table_form"
          >
            <div class="flex">
              <el-checkbox v-model="queryInfo.nameRequired">必填</el-checkbox>
              <el-form-item label="姓名">
                <el-input disabled v-model="queryInfo.name" />
              </el-form-item>
            </div>
            <div class="flex">
              <el-checkbox v-model="queryInfo.schoolRequired">必填</el-checkbox>
              <el-form-item label="所属学校">
                <el-input disabled v-model="queryInfo.school" />
              </el-form-item>
            </div>
            <div class="flex">
              <el-checkbox v-model="queryInfo.numberRequired">必填</el-checkbox>
              <el-form-item label="参加人数">
                <el-input disabled v-model="queryInfo.number" />
              </el-form-item>
            </div>
            <div class="flex">
              <el-checkbox v-model="queryInfo.phoneRequired">必填</el-checkbox>
              <el-form-item label="联系方式">
                <el-input disabled v-model="queryInfo.phone" />
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div class="book_information">
          <div class="title">书籍信息</div>
          <el-form
            label-position="right"
            label-width="80px"
            class="information_table_form"
          >
            <div
              class="information_table_form_flex"
              v-for="(item, index) in queryInfo.informationBookQueries"
              :key="index"
              style="margin-bottom: 40px"
            >
              <el-row :gutter="10">
                <el-col :span="14">
                  <el-form-item :label="'书籍' + (index + 1) + '名称'">
                    <el-input v-model="item.bookName" placeholder="请输入" />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-button
                    icon="el-icon-plus"
                    circle
                    type="success"
                    plain
                    @click="addDomain"
                  ></el-button>
                  <el-button
                    icon="el-icon-minus"
                    circle
                    type="danger"
                    plain
                    v-if="index"
                    @click.prevent="removeDomain(item)"
                  ></el-button>
                </el-col>
              </el-row>
              <el-form-item label="书籍图片">
                <div @click="getImageTypeIndex(index)">
                  <el-upload
                    class="avatar-uploader"
                    action=""
                    :http-request="upload"
                    accept="image/*"
                    :show-file-list="false"
                  >
                    <img v-if="item.image" :src="item.image" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
                <!-- <input
                alt="推荐产品图片"
                type="file"
                class="upload"
                @change="addImg(index)"
                ref="file"
                accept="image/png, image/jpeg, image/gif, image/jpg"
              />
              <el-image :src="item.image" v-if="item.image"></el-image> -->
                <div class="addinfo_title">统一尺寸为：88*115</div>
              </el-form-item>
              <el-form-item label="书籍简介" prop="videoIntroduction">
                <el-input
                  style="width: 70%"
                  v-model="item.introduction"
                  type="textarea"
                  show-word-limit
                  maxlength="40"
                />
              </el-form-item>
              <el-form-item label="价格" class="new_input">
                <el-input
                  v-model="item.heatValue"
                  @keyup="oninput"
                  type="number"
                  step="0.01"
                  placeholder="请输入"
                />
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <div class="fixed_bottom">
        <el-button @click="releaseClick">保存</el-button>
        <el-button type="primary" @click="qrcodeClick">生成二维码</el-button>
      </div>
    </el-card>
    <el-dialog
      title="二维码"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="img_src_flex"><el-image :src="imgSrc" /></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="downloadQRcode">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { uploadAdmin } from "@/api/common.js";
import {
  addInformation,
  getInformation,
  editInformation,
} from "@/api/activity.js";
export default {
  name: "addInformationTable",
  computed: {
    Authorization() {
      return sessionStorage.getItem("token");
    },
    activeId() {
      return this.$route.query.activeId;
    },
    activeName() {
      return this.$route.query.activeName;
    },
    activeType() {
      return this.$route.query.activeType;
    },
  },
  created() {
    if (this.activeType * 1) {
      this.seach();
    }
  },
  data() {
    return {
      uploadImageType: "",
      queryInfo: {
        activeId: "",
        informationBookQueries: [
          {
            bookName: "", // 书籍名称
            image: "", //图片
            heatValue: "", //价格
            introduction: "", //书籍简介
          },
        ],
        name: "名字", //名字
        nameRequired: true, //名字是否必选
        number: "参加人数", //人数
        numberRequired: true, //人数是否必选
        phone: "联系方式", //类型方式
        phoneRequired: true, //类型方式是否必选
        school: "所属学校", //学校
        schoolRequired: true, //学校是否必选
      },
      dialogVisible: false,
      imgSrc: "", //图片路径
      downloadSrc: "", //下载路径
    };
  },
  methods: {
    //解决火狐输入小数点
    oninput(e) {
      // 通过正则过滤小数点后两位
      if (
        e.target.value != "" &&
        e.key == "." &&
        -1 == e.target.value.indexOf(".")
      ) {
        //兼容火狐，数字输入框输入点 value 值自动隐藏‘.’，再进行正则会丢失‘.’
        return;
      }
      if (
        e.target.value.toString() !==
        e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]
      ) {
        e.target.value = e.target.value.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      }
    },
    //下载图片
    downloadQRcode() {
      let blob = new Blob([this.downloadSrc]);
      let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
      const a = document.createElement("a"); //创建a标签
      a.style.display = "none";
      a.href = href; // 指定下载'链接
      a.download = this.activeName + ".png"; //指定下载文件名
      // a.download = 'test.png'
      a.click(); //触发下载
      window.URL.revokeObjectURL(a.href); //释放URL对象
      if (document.body.contains(a)) {
        document.body.removeChild(a); //释放标签
      }
    },
    //关闭弹窗
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    //qrcodeClick 生成二维码
    qrcodeClick() {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_BASE_API}/wxQrc/qrcCode`,
        params: { productId: this.activeId, name: this.activeName },
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          this.imgSrc = window.URL.createObjectURL(res.data);
          this.downloadSrc = res.data;
          this.dialogVisible = true;
        })
        .catch((err) => {
          this.$message.error("二维码生成失败!");
        });
    },
    //获取单个数据
    async seach() {
      const { data: res } = await getInformation({ activeId: this.activeId });
      if (res.data) {
        this.queryInfo = Object.assign(res.data);
      }
    },
    //发布
    async releaseClick() {
      let res;
      if (this.activeType * 1) {
        res = await editInformation(this.queryInfo);
        if (res.data.success) {
          this.$message.success("修改成功!");
          // this.$router.go(-1);
        } else {
          this.$message.error("修改失败请稍候重试！");
        }
      } else {
        this.queryInfo.activeId = this.activeId;
        res = await addInformation(this.queryInfo);
        if (res.data.success) {
          this.$message.success("新增成功!");
          // this.$router.go(-1);
        } else {
          this.$message.error("新增失败请稍候重试！");
        }
      }
    },
    //add上传图片
    addImg(index) {
      let files = this.$refs.file[index].files[0]; //上传的file文件 通过$refs获取
      let formData = new FormData();
      formData.append("files", files);
      uploadAdmin(formData).then((res) => {
        this.queryInfo.informationBookQueries[index].image =
          res.data.data.fileLook[0];
      });
    },
    //获取索引index
    getImageTypeIndex(index) {
      this.uploadImageType = index;
    },
    //上传图片
    async upload(params) {
      const form = new FormData();
      form.append("files", params.file);
      const { data: res } = await uploadAdmin(form);
      if (res.success) {
        this.queryInfo.informationBookQueries[this.uploadImageType].image =
          res.data.fileLook[0];
      }
    },
    //删除单个书籍信息
    removeDomain(item) {
      var index = this.queryInfo.informationBookQueries.indexOf(item);
      if (index !== -1) {
        this.queryInfo.informationBookQueries.splice(index, 1);
      }
    },
    //新增单个书籍信息
    addDomain() {
      this.queryInfo.informationBookQueries.push({
        bookName: "", // 书籍名称
        image: "", //图片
        heatValue: "", //价格
      });
    },
  },
};
</script>

<style lang="less" scoped>
.information_table {
  width: 100%;
  height: 620px;
  overflow: auto;

  .img_src_flex {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  /deep/ .el-card {
    height: 100%;
  }

  .position_card {
    height: 680px;
    overflow: auto;
  }

  .fixed_bottom {
    width: 100%;
    overflow: hidden;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    /deep/ .el-button--default {
      background-color: #8483d0;
      border: 1px solid #8483d0;
      color: #fff;
    }

    .el-button {
      height: 40px;
      line-height: 40px;
      padding: 0;
      width: 136px;
    }
  }
  // 全局css 加上以下代码，可以隐藏上下箭头
  // 取消input的上下箭头
  /deep/input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  /deep/input[type="number"] {
    -moz-appearance: textfield;
  }

  .upload {
    width: 88px;
    height: 88px;
    background-color: #e5e5e5;
  }

  /deep/ .el-card {
    border: none;
    position: relative;
  }

  /deep/ .new_input .el-input {
    width: 240px !important;
  }

  .information_table_div {
    width: 70%;
    box-sizing: border-box;
    margin: 0 auto 10px auto;
    padding: 20px 30px;
    border: 1px solid #c4c4c4;
    border-radius: 2px;

    .title {
      color: #151515;
      font-size: 18px;
      font-weight: bold;
    }

    .information_table_form {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      padding: 20px 30px 0 20px;
    }
  }

  .information_table_title {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 30px 0 40px 0;
    border-bottom: 1px dashed #e5e5e5;
    text-align: center;
    color: #151515;
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: bold;
  }

  .flex {
    display: flex;
    flex-direction: row;

    /deep/ .el-checkbox {
      margin: 10px 25px 0 0;
    }
  }

  .book_information {
    width: 70%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 20px 30px;
    overflow: hidden;

    .title {
      color: #151515;
      font-size: 18px;
      font-weight: bold;
    }

    .information_table_form {
      width: 100%;
      overflow: hidden;
      box-sizing: border-box;
      padding: 20px 30px 0 20px;
    }
  }

  /deep/ .el-upload--picture-card {
    width: 88px;
    height: 88px;
    line-height: 96px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 88px;
    height: 88px;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    border: 1px dashed #eee;
    width: 88px;
    height: 88px;
    line-height: 88px;
    text-align: center;
  }
  .avatar {
    width: 88px;
    height: 88px;
    display: block;
  }

  .addinfo_title {
    font-size: 12px;
    color: #c4c4c4;
  }
}
</style>